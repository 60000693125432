import { render, staticRenderFns } from "./sales-order-details.vue?vue&type=template&id=9b9107a8&scoped=true&"
import script from "./js/sales-order-details.js?vue&type=script&lang=js&"
export * from "./js/sales-order-details.js?vue&type=script&lang=js&"
import style0 from "./sales-order-details.vue?vue&type=style&index=0&id=9b9107a8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9b9107a8",
  null
  
)

export default component.exports