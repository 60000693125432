import breadcrumbComp from "../../common/breadcrumb-comp.vue";
import AddFavourite from "../../utility/js/addFav.js";
import Utility from "../../../shared/utility.js";
import EncryptUtility from "../../utility/js/encrypt-utility.js";
import addressComp from "../address-comp.vue";
import moment from "moment";
import salesOrderEditService from "./sales-order-edit-service.js";
import salesService from "./sales-order-service.js";
import { bus } from "../../../main.js";
import BackToTop from "../../common/back-to-top.vue";
import commonApiService from "../../common/js/common-api-service.js";
export default {
  data() {
    return {
      route: "",
      stockMessage: "",
      showDeleteWarningMessage: true,
      required: [(v) => !!v || "Field is required"],
      priceValid: [(v) => parseInt(v) >= 0 || "Field is required"],
      userId: EncryptUtility.localStorageDecrypt("userID"),
      actionList: [],
      mainPage: "",
      favouriteList: [],
      pageUrl: "",
      subPage: "",
      showAdd: false,
      subPageUrl: "",
      bookmark: false,
      showBookMark: false,
      backSlash: true,
      backSlashParent: true,
      projectList: [],
      businessList: [],
      orderTypeList: [],
      custSearchKey: "",
      customerList: [],
      shipList: [],
      returnList: [],
      showLineDelete: false,
      shipSearchKey: "",
      returnSearchKey: "",
      outboundList: [],
      flexHeader: [
        {
          text: "Flex Attribute",
          align: "start",
          value: "flex_code",
          class: "primary customwhite--text",
        },
        {
          text: "Flex Value",
          value: "flex_data",
          class: "primary customwhite--text",
        },
      ],
      headersLineItems: [
        {
          text: "Line#",
          align: "start",
          value: "sequence_number",
          class: "primary customwhite--text",
        },
        {
          text: "Line Type",
          value: "order_line_text",
          class: "primary customwhite--text",
        },
        {
          text: "",
          value: "ouboundPresent",
          class: "primary customwhite--text",
        },
        {
          text: "Part Number To Ship",
          value: "sh_part_number",
          class: "primary customwhite--text",
        },
        {
          text: "Return Part Number",
          value: "return_part_number",
          class: "primary customwhite--text",
        },
        {
          text: "Outbound Warehouse",
          value: "sh_from_warehouse",
          class: "primary customwhite--text",
        },
        {
          text: "Inbound Warehouse",
          value: "return_to_warehouse",
          class: "primary customwhite--text",
        },
        {
          text: "Ordered Qty",
          value: "quantity",
          class: "primary customwhite--text",
        },
        { text: "Received", value: "Rcvd", class: "primary customwhite--text" },
        { text: "Picked", value: "Picked", class: "primary customwhite--text" },
        {
          text: "Shipped",
          value: "Shipped",
          class: "primary customwhite--text",
        },
        {
          text: "Open Shipments",
          value: "Open_Shipments",
          class: "primary customwhite--text",
        },
        {
          text: "Available",
          value: "Quantity",
          class: "primary customwhite--text",
        },

        {
          text: "Allocated",
          value: "Allocated",
          class: "primary customwhite--text",
        },
        {
          text: "Open Returns",
          value: "Open_Receipts",
          class: "primary customwhite--text",
        },
        {
          text: "Has BTO",
          value: "build_order_ok",
          class: "primary customwhite--text",
        },
        {
          text: "Actions",
          value: "actions",
          class: "primary customwhite--text",
        },
      ],
      headersWarrenty: [
        {
          text: "Serial No",
          align: "start",
          value: "serial_number",
          class: "primary customwhite--text",
        },
        {
          text: "Asset Tag",
          value: "asset_tag",
          class: "primary customwhite--text",
        },
        {
          text: "Problem Description",
          value: "problem_description",
          class: "primary customwhite--text",
        },
        {
          text: "Tracking",
          value: "return_tracking_number",
          class: "primary customwhite--text",
        },
        {
          text: "Return Carrier",
          value: "return_carrier",
          class: "primary customwhite--text",
        },
        {
          text: "Warranty",
          value: "warranty_status",
          class: "primary customwhite--text",
        },
      ],
      headersBuild: [
        {
          text: "Part Number",
          align: "start",
          value: "bo_part_number",
          class: "primary customwhite--text",
        },
        {
          text: "Part Description",
          value: "bo_part_description",
          class: "primary customwhite--text",
        },
        { text: "Qty", value: "bo_qty", class: "primary customwhite--text" },
      ],
      inboundDetailsHeaders: [
        {
          text: "Process Type",
          align: "start",
          value: "process_type",
          class: "primary customwhite--text",
        },
        {
          text: "Request",
          value: "request",
          class: "primary customwhite--text",
        },
        // { text: "XML", value: "request_xml", class: "primary customwhite--text" },
        {
          text: "Record Date",
          value: "created_date",
          class: "primary customwhite--text",
        },
      ],
      outboundDetailsHeaders: [
        {
          text: "Process Type",
          align: "start",
          value: "process_type",
          class: "primary customwhite--text",
        },
        {
          text: "Request",
          value: "request",
          class: "primary customwhite--text",
        },
        {
          text: "Record Date",
          value: "created_date",
          class: "primary customwhite--text",
        },
        {
          text: "Response",
          value: "response",
          class: "primary customwhite--text",
        },
        {
          text: "Sent Date",
          value: "retrieved",
          class: "primary customwhite--text",
        },
        {
          text: "Status",
          value: "msg_status",
          class: "primary customwhite--text",
        },
      ],
      ouboundLogHeader: [
        {
          text: "Client Response Json",
          align: "start",
          value: "client_response_json",
          class: "primary customwhite--text",
        },
        {
          text: "Retrieved",
          value: "retrieved",
          class: "primary customwhite--text",
        },
      ],
      inboundList: [],
      inboundDataList: [],
      outboundDataList: [],
      panel: [0, 1, 2, 3, 4],
      lineItemPanel: [0, 1],
      showFlexDialog: false,
      showPartsItem: false,
      lineTypeList: [],
      showPartShip: false,
      showPartRet: false,
      showDialogDetailsBox: false,
      dialogBoxHeader: "",
      displayData: "",
      showOutboundLog: false,
      outboundData: [],
      showFlex: false,
      showActionsList: [],
      partNumbertoShip: "",
      salesOrderId: "",
      showPartDetails: false,
      showBuildIcon: false,
      showBuildExpand: false,
      shipWareList: [],
      partBuildPartNum: "",
      buildPartResultList: [],
      buildToQty: "",
      returnWareList: [],
      partReturnSearchKey: "",
      returnResultList: [],
      returnPartNum: "",
      returnPartDesc: "",
      showexpIcon: false,
      showExpectedSr: false,
      serial_num: "",
      tracking_num: "",
      asset_tag: "",
      warr_status: "",
      returnCarrier: "",
      temp_waybill_id: 1,
      showLinkDialog: false,
      shippingReq: false,
      returnReq: false,
      selectedLink: "",
      pickedDataList: [],
      receivedDataList: [],
      shippedDataList: [],
      headersPicked: [
        {
          text: "SO Line#",
          align: "start",
          value: "SO Line#",
          class: "primary customwhite--text",
        },
        {
          text: "Part No",
          value: "Part No",
          class: "primary customwhite--text",
        },
        { text: "BCN", value: "BCN", class: "primary customwhite--text" },
        {
          text: "Serial No#",
          value: "Serial#",
          class: "primary customwhite--text",
        },
        { text: "ESN", value: "ESN", class: "primary customwhite--text" },
        { text: "Picked", value: "Picked", class: "primary customwhite--text" },
        {
          text: "From Bin(LPN)",
          value: "From Bin (LPN)",
          class: "primary customwhite--text",
        },
        { text: "To LPN", value: "To LPN", class: "primary customwhite--text" },
        { text: "Qty", value: "Qty", class: "primary customwhite--text" },
      ],
      headersReceived: [
        {
          text: "SO Line#",
          align: "start",
          value: "SO Line#",
          class: "primary customwhite--text",
        },
        {
          text: "Receiver#",
          value: "Receiver#",
          class: "primary customwhite--text",
        },
        {
          text: "Part No",
          value: "Part No",
          class: "primary customwhite--text",
        },
        { text: "BCN", value: "BCN", class: "primary customwhite--text" },
        {
          text: "Serial",
          value: "Serial#",
          class: "primary customwhite--text",
        },
        { text: "ESN", value: "ESN", class: "primary customwhite--text" },
        { text: "Date", value: "Date", class: "primary customwhite--text" },
        { text: "Tech", value: "Tech", class: "primary customwhite--text" },
        {
          text: "Packing Slip",
          value: "Packing Slip",
          class: "primary customwhite--text",
        },
        {
          text: "Waybill#",
          value: "Waybill#",
          class: "primary customwhite--text",
        },
        { text: "Qty", value: "Qty", class: "primary customwhite--text" },
        { text: "Credit", value: "Credit", class: "primary customwhite--text" },
        {
          text: "Extra Credit",
          value: "Ext Credit",
          class: "primary customwhite--text",
        },
      ],
      headersShipped: [
        {
          text: "SO Line#",
          align: "start",
          value: "SO Line#",
          class: "primary customwhite--text",
        },
        {
          text: "Shipper#",
          value: "Shipper#",
          class: "primary customwhite--text",
        },
        {
          text: "Part No",
          value: "Part No",
          class: "primary customwhite--text",
        },
        { text: "BCN", value: "BCN", class: "primary customwhite--text" },
        {
          text: "Serial",
          value: "Serial#",
          class: "primary customwhite--text",
        },
        { text: "ESN", value: "ESN", class: "primary customwhite--text" },
        {
          text: "Shipped",
          value: "Shipped",
          class: "primary customwhite--text",
        },
        { text: "Tech", value: "Tech", class: "primary customwhite--text" },
        {
          text: "Packing Slip",
          value: "Packing Slip",
          class: "primary customwhite--text",
        },
        {
          text: "Waybill#",
          value: "Waybill",
          class: "primary customwhite--text",
        },
        {
          text: "Return Waybill#",
          value: "Rtn Waybill",
          class: "primary customwhite--text",
        },
        { text: "Qty", value: "Qty", class: "primary customwhite--text" },
        { text: "Credit", value: "Credit", class: "primary customwhite--text" },
        {
          text: "Extra Credit",
          value: "Ext Credit",
          class: "primary customwhite--text",
        },
      ],
      // SO JSON
      salesOrderJson: {
        order_number: "",
        proj_id: "",
        bu_id: "",
        inbound_status: "",
        rma: "",
        ordered_date: "",
        outbound_status: "",
        customer_number: "",
        requested_ship_date: "",
        order_type: "",
        order_type_id: "",
        customer_po: "",
        customer_order_number: "",
        fob: "",
        pay_term_id: "",
        ritm: "",
        shipment_terms: "",
        employee_name: "",
        employee_id: "",
        new_hire: false,
        new_hire_date: "",
        CustomerInfo: [
          {
            title: "",
            first: "",
            last: "",
            cust_id: "",
            custno: "",
            company: "",
            address1: "",
            address2: "",
            city: "",
            state: "",
            zip: "",
            country: "",
            phone1: "",
            phone2: "",
            cell: "",
            fax: "",
            email: "",
          },
        ],
        shipment_address: [
          {
            sh_title: "",
            sh_first: "",
            sh_last: "",
            sh_custno: "",
            sh_company: "",
            sh_address1: "",
            sh_address2: "",
            sh_city: "",
            sh_state: "",
            sh_zip: "",
            sh_country: "",
            sh_phone1: "",
            sh_phone2: "",
            sh_cell: "",
            sh_fax: "",
            sh_email: "",
            sh_cust_id: "",
          },
        ],
        return_address: [
          {
            rt_title: "",
            rt_first: "",
            rt_last: "",
            rt_comp_id: "",
            rt_custno: "",
            rt_company: "",
            rt_address1: "",
            rt_address2: "",
            rt_city: "",
            rt_state: "",
            rt_zip: "",
            rt_country: "",
            rt_phone1: "",
            rt_phone2: "",
            rt_cell: "",
            rt_fax: "",
            rt_email: "",
          },
        ],
        shipping_priority: "",
        return_shipping_priority: "",
        signature_required: false,
        saturday_delivery: false,
        order_notes: "",
        problem_description: "",
        so_notes: "",
        flex_field: [],
        order_line: [],
      },
      //TEMPJSON
      temp_order_line: {
        sequence_number: "",
        order_line_type_text: "",
        order_line_type: "",
        line_number: "",
        sh_part_id: "",
        sh_part_number: "",
        sh_part_description: "",
        sh_from_warehouse_id: "",
        sh_from_warehouse: "",
        sh_from_inventory_bucket: "",
        sh_quantity: "",
        sh_price: "",
        build_orders: {
          build_order_notes: "",
          build_order: [],
        },
        return_part_id: "",
        return_part_number: "",
        return_part_description: "",
        return_to_warehouse_id: "",
        return_to_warehouse: "",
        return_to_inventory_bucket: "",
        return_quantity: "",
        return_price: "",
        return_rma: "",
        expected_serial_number: [],
        line_comment: "",
        flex_field: [],
        // Add by Dev team for showing text
        order_line_text: "",
        showPartRet: "",
        showPartShip: "",
        showBuild: "",
        showSerial: "",
      },
      soHistory: true,
      salesOrderHistoryItems: [],
      salesOrderHistoryDialog: false,
      headersSalesOrderHistory: [
        {
          text: "Action",
          align: "start",
          value: "Action",
          class: "primary customwhite--text",
        },
        { text: "Logged", value: "Logged", class: "primary customwhite--text" },
        { text: "User", value: "User", class: "primary customwhite--text" },
      ],
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "").split("/")[0];
    //to Load Data
    let data = await AddFavourite.getParentPageAction(this.userId, this.route);
    let pagePermissions = data?.actionList;
    this.mainPage = data?.mainPage;
    this.favouriteList = data?.favouriteList;
    this.pageUrl = data?.pageUrl;
    this.subPage = data?.subPage;
    this.showAdd = data?.showAdd;
    this.subPageUrl = data?.subPageUrl;
    this.appPageName = data?.appPageName;
    //New BreadCrumb format COde DO NOT Remove IT
    let actionData = await AddFavourite.getParentPageAction(
      this.userId,
      this.subPageUrl
    );
    this.actionList = actionData?.actionList;
    pagePermissions.forEach((element) => {
      this.actionList.push(element);
    });
    // to load the Project Dropdown
    let LoaderDialog = {
      visible: true,
      title: "Please Wait...",
    };
    this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    let projectData = await commonApiService.getProject("get", this.userId);
    this.projectList = projectData?.ProjectInfo;
    this.salesOrderId = atob(this.$route.params.id);
    this.getInboundGridData();
    this.getOutboundGridData();
    let salesOrderDetails = await salesOrderEditService.getSalesOrderDetails(
      "get",
      this.userId,
      this.salesOrderId
    );
    // Assigning the values we get from API to the Form JSON we created
    this.salesOrderJson = JSON.parse(
      JSON.stringify(salesOrderDetails.Result[0])
    );
    this.salesOrderJson.cdate = this.salesOrderJson?.cdate
      ? Utility.convertESTToLocal(this.salesOrderJson.cdate)
      : Utility.convertESTToLocal(new Date());
    this.salesOrderJson.udate = this.salesOrderJson?.udate
      ? Utility.convertESTToLocal(this.salesOrderJson.udate)
      : Utility.convertESTToLocal(new Date());
     
      this.salesOrderJson.ordered_date = this.salesOrderJson?.ordered_date
      ? Utility.convertESTToLocal(this.salesOrderJson.ordered_date)
      : Utility.convertESTToLocal(new Date());
      if(this.salesOrderJson.new_hire_date=="1900-01-01T00:00:00"||!this.salesOrderJson.new_hire_date)
     
      this.salesOrderJson.new_hire_date=""
    else{
      this.salesOrderJson.new_hire_date =
      Utility.convertESTToLocalDate(this.salesOrderJson?.new_hire_date)
     }
      if(this.salesOrderJson.requested_ship_date=="1900-01-01T00:00:00" || !this.salesOrderJson?.requested_ship_date){
      this.salesOrderJson.requested_ship_date = ""
     }
     else{
      this.salesOrderJson.requested_ship_date=
      Utility.convertESTToLocalDate(this.salesOrderJson?.requested_ship_date)
     }
    this.bookmark = Boolean(this.salesOrderJson.is_bookmark);
    this.showActionsList.push({
      Id: "SO-History",
      Icon: "mdi mdi-account-clock-outline",
      FriendlyName: "SO History",
    });
    if (this.bookmark)
      this.showActionsList.push({
        Id: "SO-BookMark-Remove",
        Icon: "mdi mdi-bookmark-outline",
        FriendlyName: "Remove Bookmark",
      });
    else
      this.showActionsList.push({
        Id: "SO-BookMark",
        Icon: "mdi mdi-bookmark",
        FriendlyName: "Add Bookmark",
      });
    this.showDeleteWarningMessage = Boolean(this.salesOrderJson.enable_edit_so);
    let projObj = {
      UserId: this.userId,
      ProjKey: this.salesOrderJson.proj_id,
      BuKey: 0,
      IncludeEmptyBU: 0,
      WithinSameLocation: 1,
      loca_key: 0, // Do not Delete
    };
    let businessData = await commonApiService.postBusinessData("post", projObj);
    this.businessList = businessData.map((x) => ({
      bu_id: x.Bu_id,
      business: x.Business,
    }));
    // API CALL for Order Type
    let businessObj = {
      UserId: this.userId,
      bu_key: this.salesOrderJson.bu_id,
      pass: 1,
    };
    this.lineTypeData();
    let orderTypeData = await salesOrderEditService.postOrderTypeData(
      "post",
      businessObj
    );
    this.orderTypeList = orderTypeData?.OrderTypes;
    this.paymentList = orderTypeData?.PaymentTerms;
    // Warehouse List
    // Carrier API
    let carrierObj = {
      UserId: this.userId,
      bu_key: this.salesOrderJson.bu_id,
      custpo: this.salesOrderJson.customer_po,
      order_type: this.salesOrderJson.order_type,
    };
    let carrierData = await salesOrderEditService.postcarrierServiceLvlData(
      carrierObj
    );
    this.outboundList = carrierData?.OutboundSLs;
    this.inboundList = carrierData?.InboundSLs;
    this.validateLineType();
    this.returnSearchKey = this.salesOrderJson.return_address[0].rt_company;
    this.shipSearchKey = this.salesOrderJson.shipment_address[0]?.sh_company;
    this.custSearchKey = this.salesOrderJson.CustomerInfo[0]?.company;
    LoaderDialog.visible = false;
    this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    this.setActionFunction();
    this.outboundOrderLine();
      this.interval = setInterval(() =>{
        this.resetFunction()},60000)
    
  },
  destroyed(){
    clearInterval(this.interval)
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventNav);
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (window.confirm("Leave without saving?")) {
        next();
      } else {
        return false;
      }
    }
    next();
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.salesOrderJson.ordered_date
        ? moment(String(this.salesOrderJson.ordered_date)).format("MM/DD/YYYY")
        : moment(String(new Date())).format("MM/DD/YYYY");
    },
    shipcomputeDateFormattedMomentjs() {
      return this.salesOrderJson.requested_ship_date
        ? moment(String(this.salesOrderJson.requested_ship_date)).format(
            "MM/DD/YYYY"
          )
        : "";
    },
    newHireComputedDateFormattedMomentjs() {
      return this.salesOrderJson.new_hire_date
        ? moment(this.salesOrderJson.new_hire_date).format("MM/DD/YYYY")
        : "";
    },
    createdDateFormatted() {
      return this.salesOrderJson?.cdate
        ? Utility.convertESTToLocal(this.salesOrderJson.cdate)
        : Utility.convertESTToLocal(new Date());
    },
    editedDateFormatted() {
      return this.salesOrderJson?.udate
        ? Utility.convertESTToLocal(this.salesOrderJson.udate)
        : Utility.convertESTToLocal(new Date());
    },
  },
  mounted() {},
  methods: {
    setActionFunction() {
      if (this.actionSetter("EditSalesOrder"))
        this.showActionsList.push({
          Id: "SO-Edit",
          Icon: "mdi-pencil",
          FriendlyName: "SO Edit",
        });
    },
    // Action
    actionSetter(action) {
      if (
        this.actionList !== undefined &&
        this.actionList !== null &&
        this.actionList !== ""
      ) {
        let filter = this.actionList.filter(
          (entry) => entry.SystemName === action
        );
        return filter !== undefined && filter !== null && filter.length == 1
          ? true
          : false;
      } else return false;
    },
    //Sales Order History button click event
    async salesOrderHistory() {
      let historyData = await salesService.getSalesOrderActionLog(
        this.salesOrderId,
        this.userId
      );
      if (historyData !== null && historyData !== undefined) {
        const newArr = historyData.ActionLogInfo.map((obj) => {
          return {
            ...obj,
            Logged: Utility.convertESTToLocal(obj.Logged),
          };
        });
        this.salesOrderHistoryItems = newArr;
        this.salesOrderHistoryDialog = true;
      }
    },
    // Line typr API call
    async lineTypeData() {
      let LineTypeData = await salesOrderEditService.getOrderTypeData(
        "get",
        this.userId,
        this.salesOrderJson.proj_id,
        this.salesOrderId
      );
      if (LineTypeData?.LineTypeInfo) {
        this.lineTypeList = LineTypeData.LineTypeInfo.map((x) => ({
          so_type_id: x.sotype_id,
          so_desc: x.so_desc,
          so_type: x.so_type,
          to_ship: x.to_ship,
          to_return: x.to_return,
        }));
      }
    },
    // Reset Function
    async resetFunction(){
      let LoaderDialog = {
       visible: true,
       title: "Please Wait...",
     };
     this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
       this.salesOrderDetails = await salesOrderEditService.getSalesOrderDetails("get", this.userId, this.salesOrderId);
       // Assigning the values we get from API to the Form JSON we created
       this.salesOrderJson = JSON.parse(JSON.stringify(this.salesOrderDetails.Result[0]));
       LoaderDialog.visible = false;
       this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
   },
    //View Line Item Details
    async lineItemsView(item) {
      if(item.order_line_type==1){
        this.lineItemPanel=[0];
      }
      else if(item.order_line_type==8){
        this.lineItemPanel=[1];
      }
      else{
        this.lineItemPanel=[0,1];
      }
      this.temp_order_line.flex_field = [];
      this.showPartDetails = true;
      this.showPartsItem = true;
      this.showLineDelete = true;
      this.lineTypeData();
      let warrentyData = await salesOrderEditService.getWarrentyData(
        "get",
        this.userId
      );
      if (warrentyData != "") {
        this.warrentyList = warrentyData.WarrantyData;
      }
      let lineItemData = await salesOrderEditService.getLineItemData(
        "get",
        this.userId,
        item.line_id
      );
      this.temp_order_line = JSON.parse(
        JSON.stringify(lineItemData.LineItem[0])
      );

      this.showPartsItem = true;
      this.disableLine = true;
      let obj = this.lineTypeList.filter(
        (x) => x.so_type_id == item.order_line_type
      );
      this.showPartShip = obj[0]?.to_ship == 1 ? true : false;
      this.showPartRet = obj[0]?.to_return == 1 ? true : false;
      this.showFlex = true;
      this.temp_order_line.order_line_text = obj[0]?.so_desc;
      this.temp_order_line.showPartShip = this.showPartShip;
      this.temp_order_line.showPartRet = this.showPartRet;
      this.showFlex = true;
      this.edit_line_num = item.line_number;
      this.partShipSearchKey = this.temp_order_line.sh_part_number;
      this.shipPartName = this.temp_order_line.sh_part_number;
      this.shipPartDesc = this.temp_order_line.sh_part_description;
      this.partReturnSearchKey = this.temp_order_line.return_part_number;
      this.returnPartNum = this.temp_order_line.return_part_number;
      this.returnPartDesc = this.temp_order_line.return_part_description;
      this.temp_order_line.sh_from_warehouse_id =
        this.temp_order_line.sh_from_warehouse_id +
        "_" +
        this.temp_order_line.sh_from_inventory_bucket;
      this.temp_order_line.return_to_warehouse_id =
        this.temp_order_line.return_to_warehouse_id +
        "_" +
        this.temp_order_line.return_to_inventory_bucket;
      //Ship Object
      let shipWareObj = {
        part_num: this.temp_order_line.sh_part_number
          ? this.temp_order_line.sh_part_number
          : this.temp_order_line.return_part_number,
        order_type: this.salesOrderJson.order_type,
        sotype_key: this.temp_order_line.order_line_type,
        order_bu_key: this.salesOrderJson.bu_id,
        line_id: 0, //Do not Delete
        so_key: this.salesOrderJson.so_id, //DO not Delete
        UserId: this.userId,
      };
      let shipWarehouseList = await salesOrderEditService.postShipWareData(
        "post",
        shipWareObj
      );
      // this.showBuildIcon = true // DO no Delete
      if (shipWarehouseList?.ShipFromWare) {
        this.shipWareList = shipWarehouseList.ShipFromWare.map((x) => ({
          ware: x.ware,
          ware_id: x.ware_id + "_" + x.bucket,
          bucket: x.bucket,
          from_warebuck: x.from_warebuck,
          show_text: x.show_text,
        }));

        this.stockMessage = this.shipWareList[0].show_text;
      }
      if (shipWarehouseList?.ReturnToWare != "") {
        this.returnWareList = shipWarehouseList.ReturnToWare.map((x) => ({
          ware: x.ware,
          ware_id: x.ware_id + "_" + x.bucket,
          bucket: x.bucket,
          into_warebuck: x.into_warebuck,
        }));
      }
    },
    validateLineType() {
      //User to find Unique items in an array;
      let lineItemUsedObj = this.salesOrderJson.order_list.reduce(
        (acc, cur) => {
          acc[cur.order_line_type] = true;
          return acc;
        },
        {}
      );
      let lineTypeListObj = this.lineTypeList.reduce((acc, cur) => {
        acc[cur.so_type_id] = cur;
        return acc;
      }, {});
      Object.keys(lineTypeListObj).forEach((key) => {
        if (lineItemUsedObj[key]) {
          this.shippingReq =
            this.shippingReq || Boolean(lineTypeListObj[key].to_ship);
          this.returnReq = this.returnReq || !!lineTypeListObj[key].to_return;
        }
      });
    },
    // To View hyperLinks
    async onClickedHyperLink(value, item) {
      this.showLinkDialog = true;
      this.selectedLink = value;
      if (value === "Picked") {
        let pickedData = await salesOrderEditService.getSalesOrderPickedData(
          "get",
          this.salesOrderId,
          item.line_number,
          this.userId
        );
        this.pickedDataList = pickedData.Data;
        this.pickedDataList.forEach((element) => {
          element.Picked = Utility.convertESTToLocal(element.Picked);
        });
      } else if (value === "Received") {
        let receivedData =
          await salesOrderEditService.getSalesOrderReceivedData(
            "get",
            this.salesOrderId,
            item.line_number,
            this.userId
          );
        this.receivedDataList = receivedData.Data;
      } else if (value === "Shipped") {
        let shippedData = await salesOrderEditService.getSalesOrderShippedData(
          "get",
          this.salesOrderId,
          item.line_number,
          this.userId
        );
        this.shippedDataList = shippedData.Data;
        this.shippedDataList.forEach((element) => {
          element.Shipped = Utility.convertESTToLocal(element.Shipped);
        });
      }
    },
    redirectPage() {
      this.$router.push(
        `/sales-order-edit/${btoa(this.salesOrderId.toString())}`
      );
    },
    //Add BookMark
    async addBookmark() {
      let resp = await salesOrderEditService.addBookMark(
        "get",
        this.userId,
        this.salesOrderId,
        true
      );
      if (resp?.Result) {
        this.bookmark = true;
        this.showActionsList = this.showActionsList.filter(
          (item) => item.Id != "SO-BookMark"
        );
        this.showActionsList.unshift({
          Id: "SO-BookMark-Remove",
          Icon: "mdi mdi-bookmark-outline",
          FriendlyName: "Remove Bookmark",
        });
      } else {
        this.bookmark = false;
        bus.$emit("bookmark", this.bookmark);
      }
    },
    //remove BookMark
    async removeBookmark() {
      let resp = await salesService.getDeleteBookmarkSO(
        "get",
        this.userId,
        this.salesOrderId,
        true
      );
      if (resp?.Result) {
        this.bookmark = false;
        this.showActionsList = this.showActionsList.filter(
          (item) => item.Id != "SO-BookMark-Remove"
        );
        this.showActionsList.unshift({
          Id: "SO-BookMark",
          Icon: "mdi mdi-bookmark",
          FriendlyName: "Add Bookmark",
        });
      } else {
        this.bookmark = true;
        bus.$emit("bookmark", this.bookmark);
      }
    },
    // get Inbound grid Data
    async getInboundGridData() {
      let resp = await salesOrderEditService.getInboundData(
        "get",
        1,
        this.userId,
        this.salesOrderId,
        0
      );
      this.inboundDataList = resp?.Inbound;
      this.inboundDataList?.forEach((element) => {
        element.created_date = Utility.convertESTToLocal(element.created_date);
      });
    },
    // Get outbound Grid Data
    async getOutboundGridData() {
      let resp = await salesOrderEditService.getInboundData(
        "get",
        2,
        this.userId,
        this.salesOrderId,
        0
      );

      if (resp != undefined && resp?.result != "NA") {
        this.outboundDataList = resp?.Outbound;
        this.outboundDataList?.forEach((element) => {
          element.created_date = Utility.convertESTToLocal(
            element?.created_date
          );
          element.retrieved = element?.retrieved
            ? Utility.convertESTToLocal(element?.retrieved)
            : "";
        });
      }
    },

    //on click of popup messages for outbound
    async getMessageData(msgId) {
      this.onClickDetailsView("outboundLog", msgId);
    },
    //display outbound pupup message
    onClickDetailsView(text, item) {
      // console.log("text", text, "item", item);
      this.showDialogDetailsBox = true;
      if (text == "InboundJSON") {
        this.dialogBoxHeader = "View Inbound Request JSON";
        this.displayData = item.request_json;
      } else if (text == "InboundXML") {
        this.dialogBoxHeader = "View Inbound Request XML";
        this.displayData = item.request_xml.replaceAll(">", ">\n");
      } else if (text == "OutboundXML") {
        this.dialogBoxHeader = "View Outbound Request XML";
        this.displayData = item.replaceAll(">", ">\n");
      } else if (text == "OutboundJSON") {
        this.dialogBoxHeader = "View Outbound Request JSON";
        this.displayData = item;
      } else if (text == "outboundLog") {
        this.dialogBoxHeader = "View Outbound Response";
        this.showOutboundLog = true;
        this.outboundData.push(item);
      }
    },
    // addOutbound in List
    outboundOrderLine() {
      this.salesOrderJson.order_list.forEach((element) => {
        this.lineTypeList.forEach((ele) => {
          if (ele.so_type_id == element.order_line_type) {
            element["outboundPresent"] = ele.to_ship;
          }
        });
      });
    },
  },
  components: {
    breadcrumbComp,
    addressComp,
    BackToTop,
  },
};
